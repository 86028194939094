import { createContext, useCallback, useEffect, useState } from "react";
import "../../components/PurchaseCards/purchaseCards.scss";
import { useSearchParams } from "react-router-dom";
import { exchangeToken } from "../../services/tasks/sessionManagementTasks";
import { Titles } from "../../components/Titles/Titles";
import { Feature } from "../../components/Feature/Feature";
import { PurchaseCard } from "../../components/PurchaseCards/PurchaseCards";
import { InformationCard } from "../../components//InformationCard/InformationCard";
import { getProducts } from "../../services/tasks/productInfoTasks";
import { mock2 } from "../../utils/mockData";
import { mock3 } from "../../utils/mockDataFeature";
import { mock4 } from "../../utils/mockDataSuitFeature";
import { Col, Row } from "antd";
import { Bundles, SuitFeature } from "../../model/productsResponse";
import { FeatureOverview } from "../../model/productsResponse";
import { SkeletonCard, SkeletonText } from "../../utils/Skeletons";
import { gainSightPX } from "../../gainsight";

type UpgradeContextProps = {
  inputUserCount: number | null;
  token: string;
  setMinUsersReached: (value: boolean) => void;
  setMaxUsersReached: (value: boolean) => void;
  minUsersReached: boolean;
  maxUsersReached: boolean;
};

export const UpgradeContext = createContext<UpgradeContextProps>({
  inputUserCount: 100,
  token: "",
  setMinUsersReached: () => { },
  setMaxUsersReached: () => { },
  minUsersReached: false,
  maxUsersReached: false,
});

const Upgrade: React.FC = () => {
  const [bundleInfo, setBundleInfo] = useState<Bundles[]>([{
    name: "",
    features: [],
    products: [],
    minUserCount: 10,
    maxUserCount: 500,
    defaultUserCount: 100,
  }]);
  const [featureInfo, setFeatureInfo] = useState<FeatureOverview[]>([{
    name: "",
    featureList: [],
  }]);
  const [suitFeatureInfo1, setSuitFeatureInfo1] = useState<SuitFeature>({
    suitName: "",
    featureList: [],
  });
  
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string>("");
  const [minUsersReached, setMinUsersReached] = useState<boolean>(false);
  const [maxUsersReached, setMaxUsersReached] = useState<boolean>(false);
  const [minUserCount, setMinUserCount] = useState<number>(10);
  const [maxUserCount, setMaxUserCount] = useState<number>(500);
  const [bundleType, setBundleType] = useState<string>("normal");
  const [inputUserCount, setInputUserCount] = useState<number | null>(0);
  const [responseError, setResponseError] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [defaultUserCount, setDefaultUserCount] = useState<number>(20);

  const LOCAL = false;

  useEffect(() => {
    if (maxUsersReached) {
      setBundleType("platinum");
    } else {
      setBundleType("normal");
    }
  }, [maxUsersReached]);


  const buildBundles = useCallback(() => {
    setDefaultUserCount(mock2[0].defaultUserCount);
    setBundleInfo(mock2);    
    setInputUserCount(mock2[0].defaultUserCount);
    setMinUserCount(mock2[0].minUserCount);
    setMaxUserCount(mock2[0].maxUserCount);
    setIsLoading(false);
    setResponseError(false);
    setSuitFeatureInfo1(mock4[0]);
    setFeatureInfo(mock3);
  }, []);

  const getBundlesCallback = useCallback((token: string) => {
    setIsLoading(true);
    setFeatureInfo(mock3);
    setSuitFeatureInfo1(mock4[0]);
    getProducts(token)
      .then((response) => {
        setDefaultUserCount(response[0].defaultUserCount);
        setBundleInfo(response);
        setResponseError(false);
        setInputUserCount(response[0].defaultUserCount);
        setMinUserCount(response[0].minUserCount);
        setMaxUserCount(response[0].maxUserCount);
        setIsLoading(false);        
      })
      .catch((error) => {
        console.log("Failed to fetch: ", error);
        setIsLoading(false);
        setResponseError(true);
      });    
  }, []);

  useEffect(() => {
    if (LOCAL) {
      setIsLoading(true);
      setTimeout(buildBundles, 1000);
    } else {
      if (token) {
        getBundlesCallback(token);
      }
    }
  }, [LOCAL, buildBundles, getBundlesCallback, token]);

  const getTokenCallback = useCallback(
    (session: string | null, user: string | null) => {
      exchangeToken(session!, user!)
        .then((response: any) => {
          gainSightPX(response.gainSightProps, process.env.REACT_APP_GAIN_SIGHT_TOKEN!);
          setToken(response.access_token);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    []
  );

  useEffect(() => {
    let session: string | null = searchParams.get("session");
    let user: string | null = searchParams.get("user");
    getTokenCallback(session, user);
  }, [getTokenCallback, searchParams]);

  return (
    <>
      <UpgradeContext.Provider value={{ inputUserCount, token, setMinUsersReached, setMaxUsersReached, maxUsersReached, minUsersReached }}>
        <Titles
          title="How can we help you succeed?"
          description="We make learning easy!"
          minUsers={minUserCount}
          maxUsers={maxUserCount}
        />
        {isLoading && (
          <>
          <div className="trybuy-purchase-cards">
            <div className="trybuy-purchase-cards-area">
              <Row>
                <Col >
                  <SkeletonCard />
                </Col>
                <Col >
                  <SkeletonCard />
                </Col>
              </Row>
            </div>
          </div>
          </>
        )}
        {!isLoading && !responseError && (
          <>
            <div className="trybuy-purchase-cards">
             <div className="trybuy-purchase-cards-area">
              <Row>                  
                <Col>
                  <InformationCard
                    title="How many learners will be using Litmos ?"
                    subtitle="(minimum of 20)"
                    description="If you have more than 100 learners, "
                    subdescription="we will work with you on a quote."
                    minUsers={minUserCount}
                    maxUsers={maxUserCount}
                    defaultUserCount={defaultUserCount}
                    message="learners selected"
                    inputUserCount={inputUserCount}
                    setInputUserCount={setInputUserCount}
                  />
                </Col>      
                {
                  bundleInfo.map((item, i)=>{
                    return(
                      <Col>
                      <PurchaseCard
                        bundleInfo={item}
                        userCount={inputUserCount}
                        enabled={!minUsersReached}
                        bundleType={bundleType}
                        suitDescription="Designed for smaller companies who need a scalable solution for their U.S. workforce."
                        suitInclude="LMS with our most popular features:"
                        suitFeature={suitFeatureInfo1}
                      />
                    </Col>    
                    );
                  })
                }          
                
              </Row>
             </div>
              <Feature
                title="Features Overview"
                featureInfo={featureInfo}
                bundles={bundleInfo}
              />
            </div>
          </>
        )}
        
        
        
        <div className="bottom-space"></div>

        {!isLoading && token !== "" &&  responseError && <>Could not load pricing. </>}
      </UpgradeContext.Provider>
    </>
  );
};

export default Upgrade;