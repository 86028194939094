import { TitleProps } from '../../utils/types'

import './titles.scss';

export const Titles:React.FC<TitleProps> = ({title, description, size, minUsers, maxUsers}) => {
  
  return (
    <>
      <div className="trybuy-title">
        <div className="title">{title}</div>
        <div className="description">{description}</div>   
      </div>
    </>
  )
}
