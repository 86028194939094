import Layout, { Header } from 'antd/es/layout/layout';
import './App.scss';
import Upgrade from './pages/upgrade/Upgrade';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Col } from 'antd';

function App() {
  return (
    <Layout>
      <Header>
        <Col>
          <img className="litmos-icon"
          src="https://www.litmos.com/wp-content/themes/litmos-theme/imgs/litmos-logo.svg"
          alt="litmosIcon"
        />
        </Col>
      </Header>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Upgrade/>}>
        </Route>
      </Routes>
      </BrowserRouter>
    </Layout>
  );
}

export default App;
