import { Card, Col, Row } from "antd";
import { useContext, useEffect } from "react";
import "./purchaseCards.scss";
import { PurchaseCardsProps } from "../../utils/types";
import {
  PriceTier,
  Product,
} from "../../model/productsResponse";
import Decimal from "decimal.js";
import { BuyNow } from "../BuyNow/BuyNow";
import { UpgradeContext } from "../../pages/upgrade/Upgrade";

export const PurchaseCard: React.FC<PurchaseCardsProps> = ({
  bundleInfo,
  userCount,
  enabled,
  bundleType,
  suitDescription,
  suitInclude,
  suitFeature
}) => {

  Decimal.set({ precision: 6, rounding: 8 });
  const users: number = Number(userCount);
  let perUserPrice: Decimal = new Decimal(0.0);
  let fixedBasePrice: Decimal = new Decimal(0.0);
  let cardDiv : any = null;
  let showMoreLink: any = null;

  const { maxUsersReached } = useContext(UpgradeContext);

  const getBundleTitle = () => { 
    let titleText : string; 
    if(bundleInfo.name === "Premier Suite")  {
      titleText = "What You Tried";
    }else{
      titleText = bundleInfo.name;
    }
    return <>{titleText}</>;
  };

  const getBundleDescription = () => {
    return <><div className ="trybuy-purchase-suite-description">{suitDescription}</div></>;
  };

  const processDescription = (description:string) => {
    const items = description.split('\n').filter(Boolean);
    return items.map((item, index) => (
      <p key={index}>{item.trim()}</p>
    ));
  }

  const postCreate = () => {
    if (cardDiv.clientHeight < cardDiv.scrollHeight) {
      showMoreLink.style.display = 'block';
    }
  }

  const doShowMore = (e: any) => {
    if (cardDiv.scrollHeight > cardDiv.clientHeight) {
      cardDiv.classList.remove('trybuy-purchase-products-ext');
      showMoreLink.innerText = 'Show less...';
    } else {
      cardDiv.classList.add('trybuy-purchase-products-ext');
      showMoreLink.innerText = 'Show more...';
    }
  }

  useEffect(() => {postCreate()}, []);

  const getBundleProducts = () => {
    return (
      <>
        <div className="line"></div>
        <div className ="trybuy-purchase-products">
          {suitInclude}
          {
            suitFeature.featureList.map((item, i) =>{
              return[
                <p><img className="litmos-feature-check-icon" src="checkTryBuy.svg"/>{item.feature}</p>
              ];
            })
          }

        </div>
        <div className="line"></div>
        <div className ="trybuy-purchase-products">
          
          Custom Branding & Dashboards

        </div>
        <div className="line"></div>
        <div className ="trybuy-purchase-products">
          2,600+ eLearning Courses
        </div>
      </>
    );
  };

  const getMatchingPriceTier = (userCount: number, priceTiers: PriceTier[]) => {
    return priceTiers.find((tier: PriceTier) => {
      return tier.maxUserCount >= userCount && userCount >= tier.minUserCount;
    });
  };

  const getBundleComment = () => {
    let comment = '';
    const recurringProduct = bundleInfo.products.find(b => b.purchaseEnabled && b.isRecurring);
    if (recurringProduct) {
      comment += '* Billed Annually';
    }

    const fixedProducts = bundleInfo.products.filter(b => b.purchaseEnabled && !b.isRecurring);
    if (fixedProducts && fixedProducts.length > 0) {
      fixedBasePrice = new Decimal(0.0);
      comment += (comment.length === 0) ? '* ' : ', plus a ';
      fixedProducts.forEach(p => {
        if (p.fixedPrices) {
          p.fixedPrices.forEach(pr => fixedBasePrice = fixedBasePrice.plus(pr.pricePerUser));
        }
      });
      if (fixedBasePrice.toNumber() > 0) {
         comment += `$${fixedBasePrice.toString()} One time Quick Start implementation fee`;
      }
    }

    return (
      <div className="trybuy-purchase-products-comment">{comment}</div>
    );
  };

  const getMonthlyPrice = (): number => {
    perUserPrice = new Decimal(0.0);
    const recurringProducts = maxUsersReached ? null : bundleInfo.products.filter(b => b.purchaseEnabled && b.isRecurring);
    if (recurringProducts) {
       recurringProducts.forEach(product => {
          if (product.priceTiers) {
            let priceTier = getMatchingPriceTier(users, product.priceTiers);
            perUserPrice = perUserPrice.plus(priceTier!.pricePerUser);
          } else if (product.fixedPrices) {
            product.fixedPrices.forEach(pr => perUserPrice = perUserPrice.plus(pr.pricePerUser));
          }
       });
    }
    return perUserPrice.mul(users).toNumber();
  }

  const getPricePerUser = () => {
    return (
      <div className="trybuy-purchase-price">
        {perUserPrice.toNumber() > 0 && (<span>${perUserPrice.toFixed(2)} / User</span>)}
        {perUserPrice.toNumber() <= 0 && (<span>&nbsp;</span>)}
      </div>
    );
  };

  const getBundlePrice = () => {
    let comment = perUserPrice?.toString() + "/lerner";    
    return {comment};
  };

  return (
    <>
      <Card>
        <Row>
          <Col>
            <div className="trybuy-purchase-title">{getBundleTitle()}</div>
            {getBundleDescription()}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="button-area">
              <BuyNow
                bundleName={bundleInfo.name}
                buttonEnabled={enabled}
                buttonType={bundleType}
                monthlyPrice={getMonthlyPrice()}
                learnerPrice={"($" + perUserPrice?.toString() + "/lerner)"} />
              <div ref={elem => cardDiv=elem} className ="trybuy-purchase-products-price">
                 {"($" + perUserPrice?.toString() + " / learner)"}
              </div>
            </div>
          </Col>
        </Row>    
        <Row>
          <Col>
            <div className="top-content">
              {getBundleProducts()}
            </div>
          </Col>
        </Row>
        
      </Card>
    </>
  );
};
