import { Card, Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import "./InformationCard.scss";
import { InformationCardsProps } from "../../utils/types";
import { UpgradeContext } from "../../pages/upgrade/Upgrade";
import { InputNumber } from "antd";

export const InformationCard: React.FC<InformationCardsProps> = ({title,subtitle, description,subdescription, minUsers, maxUsers, defaultUserCount, message, inputUserCount, setInputUserCount}) => {

  const { setMaxUsersReached } = useContext(UpgradeContext);
  const { setMinUsersReached } = useContext(UpgradeContext);
  const placeholder = inputUserCount?.toString();

  const handleChange = (value: number | null) => {
    if(value){
      setMaxUsersReached(maxUsers < value);
      setMinUsersReached(minUsers > value);
      setInputUserCount(Math.round(value));
    } else {
      setMaxUsersReached(false);
      setMinUsersReached(false);
      setInputUserCount(defaultUserCount);
    }
  };

  const getMessage = () => {
    let comment = inputUserCount?.toString() + " " + message;    
    return (
      <div>{comment}</div>
    );
  };

  return (
    <>
      <Card>
        <Row>
          <Col>
            <img className="litmos-new-icon" src="litmosTryBuy.png" alt="Logo litmosTryBuy" />
            <div className="inform-title">{title}</div>
            <div className="inform-subtitle">{subtitle}</div>
          </Col>
        </Row>
        <Row className="trybuy-purchase-counter-back">
          <Col>
            <div className="trybuy-purchase-counter">
              <span className="inform-span-info-min">{minUsers >= 20? minUsers : 20}</span>
              <input type="range" min={20} max={maxUsers} defaultValue={minUsers} onChange={(e)=>handleChange(parseInt(e.target.value))} className="inform-slider"></input>
              <span className="inform-span-info-max">{maxUsers}</span>
            </div>
            <div className="inform-sel-users">{getMessage()}</div>
          </Col>
        </Row>
        <Row>          
          <Col>
            <div className="inform-bottom-content">
              <div className="inform-foot-desc">{description}</div>
              <div className="inform-foot-sub-desc"><a href="https://www.litmos.com/litmos-pricing#pricing-form" target="blank">{subdescription}</a></div>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};
