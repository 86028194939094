import { FeaturesOverview } from "../model/productsResponse";

export const mock3: FeaturesOverview = [
    {
        name: "Content for Everyone",
        featureList: [{
                name: "Anti-bribery and Corruption",
                suits: []
            },
            {
                name: "Antitrust, Competition, and Consumer Protection",
                suits: []
            },
            {
                name: "Diversity, Equity, and Inclusion",
                suits: []
            },
            {
                name: "Code of Conduct and Ethics",
                suits: []
            },
            {
                name: "Health and Safety (including wellbeing)",
                suits: []
            },
            {
                name: "Harassment and Discrimination",
                suits: []
            },
            {
                name: "Global Compliance (including translations)",
                suits: []
            },
            {
                name: "Information Security",
                suits: []
            },
            {
                name: "Data Protection and Privacy",
                suits: []
            },
            {
                name: "Governance and Risk",
                suits: []
            },
            {
                name: "Healthcare Compliance",
                suits: []
            },
            {
                name: "Healthcare Revenue Cycle",
                suits: []
            },
            {
                name: "Soft Skills and Workplace Communication",
                suits: []
            }
        ]
    },
    {
        name: "Engaging Learning Experience",
        featureList: [{
                name: "Recommended courses",
                suits: []
            },
            {
                name: "Gamification & Incentives",
                suits: []
            },
            {
                name: "Self sign-up",
                suits: []
            },
            {
                name: "Dashboards",
                suits: []
            },
            {
                name: "Assessments",
                suits: []
            },
            {
                name: "Course Equivalencies",
                suits: []
            },
            {
                name: "Translation and Localization",
                suits: []
            },
            {
                name: "Learning Paths",
                suits: []
            },
            {
                name: "Video Assessments",
                suits: []
            },
            {
                name: "Learner Uploads for Compilance",
                suits: []
            },
            {
                name: "Native Mobile App",
                suits: []
            },
            {
                name: "Learning Reinforcement via Skill Boosts",
                suits: []
            },
            {
                name: "Course Ratings",
                suits: []
            },
            {
                name: "Discussion Forums",
                suits: []
            },
            {
                name: "Scheduled Course Releases in Learning Paths",
                suits: []
            }
        ]
    },
    {
        name: "Efficient Admins",
        featureList: [{
                name: "Dashboards",
                suits: []
            },
            {
                name: "Reporting with Custom Reports Engine",
                suits: []
            },
            {
                name: "SSO",
                suits: []
            },
            {
                name: "Branding & Customization",
                suits: []
            },            
            {
                name: "Native Course Authoring Tool",
                suits: []
            },
            {
                name: "Custom User Attributes",
                suits: []
            },
            {
                name: "Email Template Customization",
                suits: []
            },
            {
                name: "Course Surveys",
                suits: []
            },
            {
                name: "Bulk Import Results From External Training",
                suits: []
            },
            {
                name: "Rules Engine",
                suits: []
            },
            {
                name: "E-Commerce",
                suits: []
            },
            {
                name: "Organization, Teams, and Hierarchies",
                suits: []
            },
            {
                name: "Enrollment Approvals",
                suits: []
            },
            {
                name: "Instructor Led Training & vILT",
                suits: []
            },
            {
                name: "Assign Content - Automate Assign",
                suits: []
            },


            {
                name: "Custom Tagging with Topics & Sup-topics",
                suits: []
            },
            {
                name: "1-Click Navigation for Common Features",
                suits: []
            },
            {
                name: "Webhooks",
                suits: []
            },
            {
                name: "Translation & Localization",
                suits: []
            },
            {
                name: "Integrations",
                suits: []
            },
            {
                name: "Learner Record Store",
                suits: []
            }
        ]
    },
    {
        name: "Empowering Managers",
        featureList: [{
                name: "Dashboards",
                suits: []
            },
            {
                name: "Skills & Competencies",
                suits: []
            },
            {
                name: "Assignment Reminders and KPIs",
                suits: []
            },
            {
                name: "Reporting with Custom Reports Engine",
                suits: []
            },
            {
                name: "Native Course Authoring Tool",
                suits: []
            },
            {
                name: "Assign Content - Automate Assign",
                suits: []
            }
        ]
    },
    {
        name: "Intelligent Experience",
        featureList: [{
                name: "Recommendations",
                suits: []
            },
            {
                name: "AI Search",
                suits: []
            },
            {
                name: "AI Learning Assistant (coming soon)",
                suits: []
            },
            {
                name: "AI Course Builder (coming soon)",
                suits: []
            }
        ]
    },
    {
        name: "Extended Enterprise",
        featureList: [{
                name: "E-Commerce",
                suits: []
            },
            {
                name: "Customers, Partners, and Suppliers",
                suits: []
            },
            {
                name: "Branding & Customization",
                suits: []
            },
            {
                name: "Revenue Management",
                suits: []
            },
            {
                name: "Reporting",
                suits: []
            }
        ]
    },
    {
        name: "Embedded Experience",
        featureList: [{
                name: "SalesForce",
                suits: []
            },
            {
                name: "HRIS, ERP",
                suits: []
            }
        ]
    },    
    {
        name: "Integrations",
        featureList: [{
                name: "ADP",
                suits: []
            },
            {
                name: "Bamboo HR",
                suits: []
            },
            {
                name: "Box",
                suits: []
            },
            {
                name: "Docusign",
                suits: []
            },
            {
                name: "Shopify",
                suits: []
            }
        ]
    }
]
    