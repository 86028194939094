import { FeatureProps } from '../../utils/types'
import { Col, Row } from "antd";
import * as React from 'react';
import './Feature.scss';

export const Feature:React.FC<FeatureProps> = ({title, featureInfo, bundles}) => {
       
  return (
    <>    
        <div className="trybuy-feature">
            <div className="feature-table-title">
                <span>{title}</span>
            </div>
            {
                featureInfo.map((suit, i) =>{
                    return (
                    <>
                      <div className="feature-table">
                        <div className="feature-tr-title">
                          <div className="feature-tr">{suit.name}</div>
                          {
                            bundles.map((item, i)=>{
                              return (<div className="feature-td">{item.name}</div>)})
                          }
                        </div>
                        {
                          suit.featureList.map((item1, i) =>{
                            return [
                              <div className="feature-table">
                                <div className="feature-tr-line">
                                  <div className="feature-tr">{item1.name}</div>
                                  {
                                    bundles.map((bundle, j) => {
                                      return (
                                        <div className="feature-td-icon">
                                          <img className={bundle.features.indexOf(item1.name) >= 0?"litmos-feature-icon":"litmos-feature-hidden-icon"}
                                            src="checkCircleTryBuy.svg"
                                            alt="checkCircleTryBuy"/>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            ];
                          })
                        }
                      </div>
                    </>
                        
                    );
                })
            }  
        <div className="bottom-space"></div>
      </div>
    </>
  )
}