import { Card, Skeleton } from "antd";
import "../components/UserCount/userCount.scss";

export const SkeletonCard = () => {
    return (
        <>
            <Card>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
            </Card>
        </>
    );
}

export const SkeletonText = () => {
    return (
        <>
            <Skeleton active className="trybuy-user-count"/>
        </>
    )
}
