import { handleResponse, handleError } from "./utilities/response";
import axios from "axios";
import urlcat from "urlcat";
import { StripeCreateSessionResponse  } from "../../model/stripeUrlResponse";
const service = "checkout-session";

const checkOutUrl = async (token: string, quantity: number|null, bundleName: string) : Promise<StripeCreateSessionResponse> => {
    let path_url = service;
    const bearer = `Bearer ${token}`;
    let config = {
        headers: {
            Authorization : bearer
        }
    }

   let model = {
    "BundleName": bundleName,
    "Quantity": quantity
   }
       
    return axios
        .post(urlcat(process.env.REACT_APP_BASE_URL_STRIPE_SUBSCRIPTION!, path_url), model, config)
        .then(handleResponse)
        .catch(handleError);
}

export { checkOutUrl }