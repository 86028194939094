import { useContext, useState } from "react";
import { Button } from "antd";
import { UpgradeContext } from "../../pages/upgrade/Upgrade";
import { checkOutUrl } from "../../services/tasks/stripeTasks";
import "./buyNow.scss"
import { BuyNowProps } from "../../utils/types";

export const BuyNow:React.FC<BuyNowProps> = ({
  bundleName,
  buttonEnabled,
  buttonType,
  monthlyPrice,
  learnerPrice
}) => {
  const {inputUserCount, token} = useContext(UpgradeContext);
  const [redirecting, setRedirecting] = useState<boolean>(false);

  const redirectToStripe = () => {
    setRedirecting(true);
    checkOutUrl(token, inputUserCount, bundleName).then((response) => 
    {
      if (response != null) {
        setRedirecting(false);
        window.location.replace(response.frontEndRedirectUri);
      }
    }).catch((error) => {
      setRedirecting(false);
      console.log("Unable to redirect: ", error);

    });
  };

  const requestQuote = () => {
    window.location.replace("https://www.litmos.com/litmos-pricing#pricing-form ")
    setRedirecting(false);
  }

  return (    
    <>
    <Button
        className={buttonEnabled?"buy-now-button-enabled":"buy-now-button-disabled "}
        type="primary"
        size="large"
        disabled={!buttonEnabled}
        onClick={buttonType==="normal"?redirectToStripe:requestQuote}
        loading={redirecting}>
        {monthlyPrice > 0 && (<div className="month-price" ><span  className="month-price-value">${monthlyPrice.toFixed(2)}</span> <span>/ month</span></div>)}            
      </Button>   
    </>
    
  );
};
