import { SuitFeatures } from "../model/productsResponse";

export const mock4: SuitFeatures = [
    {
        suitName: "What You Tried",
        featureList: [{
                feature: "Quick deployment"
            },
            {
                feature: "Simple integration"
            }, 
            {
                feature: "Built-in content authoring"
            }, 
            {
                feature: "Robust admin features"
            }
        ]
    }
]