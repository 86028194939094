type GainSightProps = {
  organisationId?: number,
  userId?: number,
  parentOrgID: string,
  sfdcId: string,
  salesforceOrgName: string,
  isInternal: string,
  name: string
};

const gainSightSetup = (n: any, t: any, a: any, e: any, co: any = null): Function => {
  var i = "aptrinsic";
  if (!n[i]) {
    n[i] = function () { (n[i].q = n[i].q || []).push(arguments) };
    n[i].p = e; n[i].c = co;
    var r = t.createElement("script");
    r.async = !0;
    r.src = a + "?a=" + e;
    var c = t.getElementsByTagName("script")[0];
    c.parentNode.insertBefore(r, c);
  }

  return n[i];
};

export const gainSightPX = (props: GainSightProps, token: any) => {
  if (token && token !== '') {
    const aptrinsic = gainSightSetup(window, document, "https://web-sdk.aptrinsic.com/api/aptrinsic.js", token);
    if (props) {
      const userFields = {
        "id": `${props.userId}:${props.organisationId}`
      };
      const accountFields = {
        "id": props.organisationId,
        "parentOrgID": props.parentOrgID,
        "sfdcId": props.sfdcId,
        "salesforceOrgName": props.salesforceOrgName,
        "isInternal": props.isInternal,
        "name": props.name
      };
      aptrinsic("identify", userFields, accountFields);
    }
  }
};
