import { handleResponse, handleError } from "./utilities/response";
import axios from "axios";
import urlcat from "urlcat";
import { BundleResponse } from "../../model/productsResponse";

const service = "products";

const getProducts = async (token:string) : Promise<BundleResponse> => {
    let path_url = service;
    const bearer = `Bearer ${token}`;
    let config = {
        headers: {
            Authorization : bearer
        }
    }
    
    return axios
        .get(urlcat(process.env.REACT_APP_BASE_URL_STRIPE_SUBSCRIPTION!, path_url), config)
        .then(handleResponse)
        .catch(handleError);
}


export { getProducts }