import { handleResponse, handleError } from "./utilities/response";
import axios from "axios";
import urlcat from "urlcat";
import { SessionResponse } from "../../model/sessionResponse";

const service = "session";

const exchangeToken = async (session : string ,user : string) : Promise<SessionResponse> => {
    let path_url = service + "/:session/auth";

    return axios
        .post(urlcat(process.env.REACT_APP_BASE_URL_SESSION_MANAGEMENT! , path_url, { session: session, user : user }))
        .then(handleResponse)
        .catch(handleError);
}

export { exchangeToken }